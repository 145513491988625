import React from 'react'
import './SecondContentBox.css'

function SecondContent() {
    return (
        <div className="bg-gray-100 sm:px-10">
            <div className="sm:grid sm:grid-cols-2">
                <div className="content-box text-center m-2">
                    <h1 className="text-content p-4 sm:p-0 sm:mt-10 text-3xl text-white tracking-wider">Extract Data</h1>
                </div>
                <div className="m-2 text-center text-white report-box">
                    <h1 className="text-3xl p-4 sm:p-0 mt-10 tracking-wider">Real-Time Reports</h1>
                </div>
                <div className="analytics-box m-2 text-center text-white">
                    <h1 className="text-3xl mt-10  p-4 sm:p-0 tracking-wider">Comprehensive Analytics</h1>
                </div>
                <div className="info-box m-2 text-center text-white">
                    <h1 className="text-content text-3xl mt-10 p-4 sm:p-0 tracking-wider">Informed Decisions</h1>
                </div>                
            </div>       
        </div>
    )
}

export default SecondContent;
